import toast, { Toaster } from "react-hot-toast";
import { useTranslation } from "react-i18next";
import _ from "lodash";
import { useState, useEffect } from "react";
import { AccountClient, EnableTwoFactorCommand } from "../../swagger";
import { axiosInstance } from "../../services/AxiosInstance";
import "react-step-progress-bar/styles.css";
import getUserInfo from "../../swr/getUserInfo";
import PasswordComponent from "../../components/PasswordComponent";
import classNames from "classnames";
import { Link } from "react-router-dom";

type propType = {};

const ChangePassword = (props: propType) => {
  const { t } = useTranslation();
  const { user } = getUserInfo();
  const [loading, setLoading] = useState(false);
  const isMobile = window.innerWidth < 768;

  const onSubmit = async (values) => {
    const authResult = new URLSearchParams(window.location.search);
    const redirectUrl = authResult.get("ReturnUrl");
    const client = new AccountClient(undefined, axiosInstance);
    setLoading(true);

    try {
      var res = await client.changePassword(
        values.currentPassword,
        values.password,
        redirectUrl
      );

      if (res && res.succeeded) {
        toast.success(t("identity.profile.updated"));
        setLoading(false);
      } else {
        setLoading(false);
        toast.error(_.first(res.errors) as any);
      }
    } catch (error) {
      setLoading(false);
      toast.error(
        t(
          "identity.src.components.login.EnterEmail.somethingUnexpectedHappenedPleaseTryAgainLater"
        )
      );
    }
  };
  return (
    <>
      <Toaster position="top-center" reverseOrder={true}></Toaster>
      <div className="space-y-6  md:col-span-9 md:px-0 lg:col-span-8 2xl:col-span-9">
        <div className={classNames("bg-white pt-6 overflow-hidden", {"shadow-[0_3px_5px_0_rgba(9,30,66,0.10)] rounded-[15px]":!isMobile})}>
          <div className="px-4 sm:px-6">
            <h2 className="text-lg font-medium leading-6 text-gray-900">
              {t("identity.profile.ChangePassword")}
            </h2>
          </div>
          <div
            className={classNames(
              "px-4 sm:px-6",
              { "w-[60%]": !isMobile },
              { "w-full": isMobile }
            )}
          >
            {user?.hasPassword ? (
              <PasswordComponent
                loading={loading}
                getCurrentPassword={true}
                handleSubmit={onSubmit}
                waitForRedirect={false}
              />
            ) : (
              <>
                <div className="flex flex-col gap-y-4 py-4">
                  <p className="text-base font-[600] leading-6 text-gray-900 ">
                    {t("You must create a password first")}
                  </p>
                  <Link to="/account/ForgetPassword">
                    <a className="text-blue-700 underline cursor-pointer">
                      {t("identity.emailAddresses.setAPassword")}
                    </a>
                  </Link>
                </div>
              </>
            )}
          </div>
        </div>
      </div>
    </>
  );
};
export default ChangePassword;
