import { Field, Form, Formik } from "formik";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { TextInput } from "./FormFields";
import { ProgressBar } from "react-step-progress-bar";
import { Link } from "react-router-dom";
import {CalculateAlphanumericPasswordStrengthLevel} from "./PasswordStrengthUtility"
type propType = {
  getCurrentPassword: boolean;
  handleSubmit: (values: any) => void;
  loading: boolean;
  waitForRedirect: boolean;
};

const PasswordComponent = (props: propType) => {
  const { t } = useTranslation();
  const [disabled, setDisabled] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const [showNewPassword, setShowNewPassword] = useState(false);
  const [showRetypePassword, setShowRetypePassword] = useState(false);
  const [score , setScore] = useState(0)
  const errorTexts = [
    "At least 12 characters",
    "Contains a number and symbol",
    "Uppercase character eg. A. B. C",
  ];
    const [errorsData, setErrorsData] = useState([0, 0, 0]);

  const checkValidate = (values) => {
    let errors = {} as any;
    if (
      props.getCurrentPassword &&
      (values.currentPassword == null || values.currentPassword == "")
    ) {
      errors.currentPassword = "Enter your current password";
    }
    if (!values.password || values.password === "") {
      setScore(0)
    } else {
      var score = CalculateAlphanumericPasswordStrengthLevel(values.password)
      setScore(score)
      // if (values.password.length > 0 && values.password.length < 12) {
      //   updatedErrors[0] = 1;
      // } else {
      //   updatedErrors[0] = 2;
      // }
      // if (/\d/.test(values.password) && /[!@#$%^&*]/.test(values.password)) {
      //   updatedErrors[1] = 2;
      // } else {
      //   updatedErrors[1] = 1;
      // }
      // if (/[A-Z]/.test(values.password)) {
      //   updatedErrors[2] = 2;
      // } else {
      //   updatedErrors[2] = 1;
      // }
    }
    if (values.password != values.retypePassword) {
      errors.retypePassword = "The password is not matched";
    }
    // console.log("errors", errors);
    // const newPercent = (updatedErrors.filter((s) => s == 2).length / 3) * 100;
    // setPercent(newPercent);
    // setErrorsData(updatedErrors);
    return errors;
  };

  var onSubmit = (values: any) => {
    console.log("values", values);
    if (score>=7) {
      props.handleSubmit(values);
    }
  };

  return (
    <Formik onSubmit={onSubmit} initialValues={{}} validate={checkValidate}>
      {({
        values,
        errors,
        touched,
        handleChange,
        handleBlur,
        handleSubmit,
        isSubmitting,
        setFieldValue,
      }) => (
        <Form>
          <div className="my-6">
            <div className="-my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
              <div className="inline-block min-w-full sm:px-6 lg:px-8">
                <div className="border-gray-200">
                  <div className="grid grid-cols-1 gap-y-6">
                    <div className="w-full h-fit space-y-5">
                      {props.getCurrentPassword ? (
                        <div className="flex flex-col space-y-1">
                          <p className="text-sm flex justify-start text-slate-400 ">
                            {t("identity.profile.typeYourCurrentPassword") +
                              "*"}
                          </p>
                          <Field
                            disabled={disabled}
                            showPassword={showPassword}
                            setShowPassword={setShowPassword}
                            name="currentPassword"
                            value={values.currentPassword}
                            onChange={handleChange}
                            title=""
                            component={TextInput}
                            placeholder={t("identity.profile.currentPassword")}
                            isPassword
                          />
                        </div>
                      ) : null}

                      <div className="flex flex-col space-y-1">
                        <p className="text-sm flex justify-start text-slate-400 ">
                          {t("identity.profile.typeYourNewPassword") + "*"}
                        </p>
                        <Field
                          disabled={disabled}
                          showPassword={showNewPassword}
                          setShowPassword={setShowNewPassword}
                          name="password"
                          value={values.password}
                          onChange={handleChange}
                          title=""
                          component={TextInput}
                          placeholder={t("identity.profile.newPassword")}
                          isPassword
                        />
                      </div>
                      <div className="flex flex-col space-y-2 mt-3">
                        <div className="">
                          <ProgressBar
                            percent={score*10}
                            height="4px"
                            filledBackground={
                              score >= 0 && score < 3
                                ? "#DC3545"
                                : score >=3 && score < 6
                                ? "#FC8A20"
                                : "#00917C"
                            }
                          />
                        </div>
                        <div className="pt-2 flex flex-col space-y-2">
                          {errorTexts.map((text, index) => (
                            <>
                              <div className="flex flex-row space-x-1 items-center">
                                <span
                                  className={`  ${
                                    errorsData[index] == 0
                                      ? "icon-Ellipse-2840-Stroke text-[#828A96]"
                                      : errorsData[index] == 1
                                      ? "icon-wrong text-[#DC3545]"
                                      : "icon-valid text-[#00917C]"
                                  } `}
                                ></span>
                                <div
                                  className={` ${
                                    errorsData[index] == 0
                                      ? "text-[#828A96]"
                                      : errorsData[index] == 1
                                      ? "text-[#DC3545]"
                                      : "text-[#00917C]"
                                  } text-sm font-[400]`}
                                >
                                  {text}
                                </div>
                              </div>
                            </>
                          ))}
                        </div>
                      </div>
                      <div className=" flex flex-col space-y-1">
                        <p className="text-sm flex justify-start text-slate-400 ">
                          {t("identity.profile.reTypeYourNewPassword") + "*"}
                        </p>
                        <Field
                          disabled={disabled}
                          showPassword={showRetypePassword}
                          setShowPassword={setShowRetypePassword}
                          name="retypePassword"
                          title=""
                          value={values.retypePassword}
                          onChange={handleChange}
                          component={TextInput}
                          placeholder={t("identity.profile.reTypePassword")}
                          isPassword
                        />
                      </div>

                      <button
                        type="submit"
                        className="inline-flex w-fit items-center gap-x-2 rounded-md bg-[#1A5DBC]  py-2.5 px-3.5 text-sm font-semibold text-white shadow-sm  focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                      >
                        {!props.loading ? (
                          <>{t("identity.profile.savePassword")}</>
                        ) : (
                          <>
                            <svg
                              role="status"
                              className="inline w-4 h-4 mr-3 text-white animate-spin"
                              viewBox="0 0 100 101"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <path
                                d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
                                fill="#E5E7EB"
                              />
                              <path
                                d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
                                fill="currentColor"
                              />
                            </svg>
                            {t(
                              "identity.src.components.login.CompletePersonalInfo.Loading"
                            )}
                          </>
                        )}
                      </button>
                      {props.waitForRedirect ? (
                        <p className="text-green-500 text-xs italic block pt-1">
                          {t(
                            "identity.src.components.login.VerifyCode.Redirecting"
                          )}
                          ...
                        </p>
                      ) : null}
                      {props.getCurrentPassword ? (
                        <Link to="/account/ForgetPassword">
                          <a className="cursor-pointer text-sm text-gray-500 block pt-1">
                            {t("identity.basicProfileInfo.ForgotPassword")}
                          </a>
                        </Link>
                      ) : null}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Form>
      )}
    </Formik>
  );
};

export default PasswordComponent;
