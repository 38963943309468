import { Switch } from "@headlessui/react"
import { PencilSquareIcon, TrashIcon } from "@heroicons/react/20/solid"
import classNames from "classnames"
import { useTranslation } from "react-i18next"
import { useState , useEffect } from "react"
import { AccountClient, RemovePhoneNumberCommand, UserPhoneNumberDto, UserPreferencesClient } from "../../swagger"
import { axiosInstance } from "../../services/AxiosInstance"
import toast from "react-hot-toast"
import { mutate } from "swr"
import _ from "lodash"
import Loading from "../Loading"
import CheckPassword from "./checkPassword"

type propType ={
    phone:UserPhoneNumberDto,
    setEditPhoneNumber: (id:Number) => void,
    hasPassword:boolean
}

const PhoneComponent = (props:propType)=> {
    const {phone} = props
    const [useForResetLoading , setUserForResetLoading] = useState(false)
    const [makePrimaryLoading , setMakePrimaryLoading] = useState(false)
    const [showPasswordModal, setShowPasswordModal] = useState(false)
    const [deletePhoneNumberId , setDeletePhoneNumberId] = useState<number|null>(null)
    const [loading , setLoading] = useState(false)
    const {t} = useTranslation()

    const onEdit = () =>{
        props.setEditPhoneNumber(phone.id)
    }

    const onDelete = () =>{
        setDeletePhoneNumberId(phone.id)
        setShowPasswordModal(true)
    }

    
  const removePhoneNumber = async (password) => {
    const client = new UserPreferencesClient(undefined, axiosInstance);
    setLoading(true)
    try {
        var command = {
            userPhoneNumberId:deletePhoneNumberId,
            password:password
        } as RemovePhoneNumberCommand
      var res = await client.removePhoneNumber(command);
      if(res && res.succeeded){
        mutate("userPreferences.getAllPhoneNumbers");
      }else{
        toast.error(_.first(res.errors) as any)
      }
      setLoading(false)
    } catch (error) {
        toast.error(t("identity.src.components.login.EnterEmail.somethingUnexpectedHappenedPleaseTryAgainLater"))
    }
  };

    const makePrimary = async () =>{
        setMakePrimaryLoading(true)
        const client = new UserPreferencesClient(undefined, axiosInstance);
        try {
            var res = await client.makePhoneNumberPrimary(phone.id)

            if (res && res.succeeded) {
                setMakePrimaryLoading(false)
                toast.success("Updated!")
                // setLoading(false);
                mutate('userPreferences.getAllPhoneNumbers')
                // TokenStorage.storeToken(res.data. as string);
                // TokenStorage.storeRefreshToken(res.refresh_token as string);
                // window.location.replace("/");
            } else {
                setMakePrimaryLoading(false)
                // setLoading(false);
                toast.error(_.first(res.errors) as any)
                // addToast(res.error, { appearance: "error" });
            }
        } catch (error) {
            // setLoading(false);
            toast.error("Something went wrong!")
            // addToast("Server Error Occured", { appearance: "error" });
        }
    }
    
    const forEnableCallApi = async () =>{
        const client = new UserPreferencesClient(undefined, axiosInstance);
        setUserForResetLoading(true)
        try {
            var res = await client.usePhoneNumberForReset(phone.id)

            if (res && res.succeeded) {
                setUserForResetLoading(false)
                toast.success(t('identity.profile.updated'))
                // setLoading(false);
                mutate('userPreferences.getAllPhoneNumbers')
                // TokenStorage.storeToken(res.data. as string);
                // TokenStorage.storeRefreshToken(res.refresh_token as string);
                // window.location.replace("/");
            } else {
                setUserForResetLoading(false)
                // setLoading(false);
                toast.error(_.first(res.errors) as any)
                // addToast(res.error, { appearance: "error" });
            }
        } catch (error) {
            // setLoading(false);
            toast.error(t("identity.src.components.login.EnterEmail.somethingUnexpectedHappenedPleaseTryAgainLater"))
            // addToast("Server Error Occured", { appearance: "error" });
        }
    }

    const onEnableChange = async (enable) =>{
        setUserForResetLoading(true)
        forEnableCallApi()
    }



    return(
        <>
            <div className="flex gap-x-2 items-center mb-5">
                <p className="text-sm font-medium">
                    {phone.phoneCountryId} {phone.phoneNumber}
                </p>
                <div onClick={onEdit} className="flex cursor-pointer">
                    <PencilSquareIcon className="h-5 w-5 text-blue-600"/>
                    <p className="text-sm text-blue-600">
                        {t('identity.phoneNumbers.Edit')}
                    </p>
                </div>
                <div onClick={onDelete} className="flex cursor-pointer">
                    <TrashIcon className="h-5 w-5 text-blue-600"/>
                    <p className="text-sm text-blue-600">
                        {t('identity.phoneNumbers.Delete')}
                    </p>
                </div>
            </div>

            <p className="text-sm text-gray-400">
                {t('identity.phoneNumbers.TheseWontBeDisplayedOnYourProfile')}
            </p>

            {!useForResetLoading?
            <div className="flex items-center gap-x-2">
                <p className="text-sm font-medium">
                    {t('identity.phoneNumbers.useForResettingPassword')}
                </p>
                <div className="flex items-center">
                    <Switch
                        checked={props.phone.useForReset}
                        onChange={onEnableChange}
                        className={classNames(
                            props.phone.useForReset ? ' bg-[#1A5DBC] ' : 'bg-gray-200',
                        'relative inline-flex h-5 w-9 flex-shrink-0 cursor-pointer rounded-full border-2 border-transparent transition-colors duration-200 ease-in-out focus:outline-none focus:ring-0'
                        )}
                    >
                        <span
                        aria-hidden="true"
                        className={classNames(
                            props.phone.useForReset ? 'translate-x-4' : 'translate-x-0',
                            'pointer-events-none inline-block h-4 w-4 transform rounded-full bg-white shadow ring-0 transition duration-200 ease-in-out'
                        )}
                        />
                    </Switch>
                </div>
            </div>:<Loading/>}

            <p className="text-sm text-gray-400">
                {t('identity.phoneNumbers.TheseWontBeDisplayedOnYourProfile')}
            </p>
            {!phone.isPrimary?
                makePrimaryLoading?
                    <Loading/>
                :
                    <button
                        onClick={makePrimary}
                        type="button"
                        className="rounded-md w-fit bg-white py-2 px-3.5 text-sm font-semibold text-blue-600 shadow-sm border-solid border-[1px] border-blue-600"
                    >
                        {t('identity.phoneNumbers.MakePrimary')}
                    </button>
            :null}

            <CheckPassword
                    setOpenModal={setShowPasswordModal}
                    openModal={showPasswordModal}
                    handleSubmit={removePhoneNumber}
                    loading={loading}
                    hasPassword={props.hasPassword}
                    result ={""}
                />
        </>
    )
}

export default PhoneComponent