import classNames from "classnames";
import { useTranslation } from "react-i18next";
import { useEffect, useState, useMemo } from "react";
import _ from "lodash";
import { AccountClient, EnableTwoFactorCommand, TwoFactorProvider } from "../../swagger";
import { axiosInstance } from "../../services/AxiosInstance";
import toast from "react-hot-toast";
import { Switch } from "@headlessui/react";
import CheckPassword from "./checkPassword";

type propType = {
  twoFactorEnabled:boolean,
  setQrcode:(code:string) => void ,
  setStep:(step:number) => void ,
  hasPassword:boolean,
  setTwoFactorEnabled:()=>void,
  twoStepProvider:TwoFactorProvider
};

const EditTwoStepVerificationSetUp = (props: propType) => {
  const { t } = useTranslation();
  const [enabled, setEnabled] = useState(props.twoFactorEnabled);
  const [openModal, setOpenModal] = useState<boolean>(false);
  // const [qrcode, setQrcode] = useState<any>("");
  const [loading, setLoading] = useState(false);
  const [result, setResult] = useState(null);


  
  const handlesubmit = async (password) => {
    const client = new AccountClient(undefined, axiosInstance);
    setLoading(true);
    setResult(null);
    const command = {
      user: undefined,
      provider: props.twoStepProvider,
      phoneNumberId: 0,
      password: password,
      isEnable: false,
      isResend: false,
    } as EnableTwoFactorCommand;

    try {
      var res = await client.enableTwoFactorAuthentication(command);

      if (res && res.succeeded ) {
        toast.success(t('identity.profile.updated'));
        props.setQrcode(res?.formattedKey);
        setLoading(false);
        setOpenModal(false);
        props.setTwoFactorEnabled();
      } else {
        setResult(res.errors);

        toast.error(_.first(res.errors) as any);
        setLoading(false);
      }
    } catch (error) {
      toast.error(t("identity.src.components.login.EnterEmail.somethingUnexpectedHappenedPleaseTryAgainLater"))
      setLoading(false);
    }
  };

  return (
    <>
      <div className="space-y-6 sm:px-6 md:col-span-9 md:px-0">
        <div className="bg-white pt-6 shadow-[0_3px_5px_0_rgba(9,30,66,0.10)] rounded-[15px] overflow-hidden ">
          <div className="px-4 sm:px-6">
            <h2 className="text-lg font-[600] leading-6 text-gray-900">
              {t("identity.profile.TwoStepVerification")}
            </h2>
          </div>

          <div className="mt-6 flex flex-col">
            <div className="-my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
              <div className="inline-block min-w-full align-middle sm:px-6 lg:px-8">
                <div className="overflow-hidden border-t border-gray-200">
                  <div className="px-6 pt-4 !pb-9 grid grid-cols-1 gap-y-6">
                    <div className="flex flex-row justify-between items-center border-b-[0.5px] border-[rgba(0,0,0,0.08)] pb-9">
                      <p className="text-base font-[400] leading-6 text-gray-800">
                        {t("identity.profile.TwoStepVerification")}
                      </p>

                      <div className="flex flex-row justify-center items-center space-x-5 ">
                        <p className="text-base font-[400]  text-gray-400">
                          {enabled ? "On" : "Off"}{" "}
                        </p>
                        <Switch
                          checked={enabled}
                          disabled={loading}
                          onChange={()=> setOpenModal(true)}
                          className={classNames(
                            enabled ? "bg-[#1A5DBC]" : "bg-gray-200",
                            "relative inline-flex h-6 w-11 flex-shrink-0 cursor-pointer rounded-full border-2 border-transparent transition-colors duration-200 ease-in-out focus:outline-none focus:ring-2 focus:ring-[#1A5DBC] focus:ring-offset-2"
                          )}
                        >
                          <span className="sr-only">Use setting</span>
                          <span
                            aria-hidden="true"
                            className={classNames(
                              enabled ? "translate-x-5" : "translate-x-0",
                              "pointer-events-none inline-block h-5 w-5 transform rounded-full bg-white shadow ring-0 transition duration-200 ease-in-out"
                            )}
                          />
                        </Switch>
                      </div>
                    </div>
                    <p className="text-base font-[400] leading-7 text-[rgba(0,0,0,0.6)]">
                      {t(
                        "identity.profile.WellSendAVerificationCodeToYourPhoneNumberEnding"
                      )}
                    </p>

                    <div
                      onClick={() => props.setStep(2)}
                      className="text-lg font-[500]  text-[#1A5DBC] cursor-pointer"
                    >
                      {t("identity.profile.ChangeVerificationMethod")}
                    </div>

                    {/* <div className="w-fit">
                      <button
                        onClick={() => props.setStep(2)}
                        className="text-[#1A5DBC] text-[14px] font-[600] cursor-pointer py-2 px-5 border-[#1A5DBC] border-[1px] rounded-md"
                      >
                        {t("identity.basicProfileInfo.Back")}
                      </button>
                    </div> */}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <CheckPassword
        setOpenModal={setOpenModal}
        openModal={openModal}
        handleSubmit={handlesubmit}
        loading={loading}
        hasPassword={props.hasPassword}
        result ={result}
      />
    </>
  );
};
export default EditTwoStepVerificationSetUp;
