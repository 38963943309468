import React, { Fragment, useState } from "react";
import Uppy from "@uppy/core";
import { DashboardModal, useUppy } from "@uppy/react";
import ImageEditor from "@uppy/image-editor";
import "@uppy/core/dist/style.css";
import "@uppy/dashboard/dist/style.css";
import "@uppy/image-editor/dist/style.css";
import { useTranslation } from "react-i18next";
import { CircularProgressbar, buildStyles } from "react-circular-progressbar";
import { PlusCircleIcon } from "@heroicons/react/20/solid";
import XHRUpload from "@uppy/xhr-upload";
import { floor } from "lodash";

type propType = {
  imgPath: string;
  percentage: number;
  value?: any;
  onChange?: (e: any) => void;
};

const LogoUploader = (props: propType) => {
  const { percentage, value, onChange } = props;

  const [uploadedFile, setUploadedFile] = useState<any>(null);
  const [open, setOpen] = useState(false);
  //   const { data: session, status } = useSession()
  //   const token = session?.accessToken;
  const token = {};
  const onDelete = () => {
    setOpen(false);
    onChange(null);
    setUploadedFile(null);
  };

  const styles = buildStyles({
    strokeLinecap: "butt",
    pathColor: "#1A5DBC",
    trailColor: "#d6d6d6",
  });
  const { t } = useTranslation();

  const uppy = useUppy(() => {
    return new Uppy({
      allowMultipleUploadBatches: false,
      restrictions: {
        allowedFileTypes: ["image/*"],
        maxNumberOfFiles: 1,
      },
    })
      .use(ImageEditor, {
        quality: 0.8,
        cropperOptions: {
          viewMode: 1,
          background: false,
          autoCropArea: 1,
          responsive: true,
          croppedCanvasOptions: {},
        },
        actions: {
          revert: true,
          rotate: true,
          granularRotate: true,
          flip: true,
          zoomIn: true,
          zoomOut: true,
          cropSquare: true,
          cropWidescreen: true,
          cropWidescreenVertical: true,
        },
      })
      .use(XHRUpload, {
        endpoint: `/api/Account/UpdateProfilePic`,
        // overridePatchMethod: true,
        // chunkSize: 5000000,
        // // withCredentials: true,
        // headers: {
        //   Authorization:`Bearer ${token}`,
        // },
      })
      .on("complete", (file:any) => {
        if (file.successful && file.successful.length > 0) {
          const url = file.successful[0].response.body.redirectUrl;
          console.log("file", file, url);
          onChange(url);
          setUploadedFile(file.successful ? file.successful[0].preview : null);
        }
      });
  });

  return (
    <Fragment>
      <div className="relative flex-shrink-0 w-fit ">
        <div
          className="flex flex-wrap justify-center items-center w-[60px] h-[60px]"
          // style={{ width: "100px", height: "100px" }}
        >
          {value || uploadedFile ? (
            <img
              className="h-[50px] w-[50px] rounded-full"
              src={uploadedFile || value}
              alt=""
            />
          ) : (
            <svg
              className="h-[50px] w-[50px] rounded-full"
              fill="currentColor"
              viewBox="0 0 24 24"
            >
              <path d="M24 20.993V24H0v-2.996A14.977 14.977 0 0112.004 15c4.904 0 9.26 2.354 11.996 5.993zM16.002 8.999a4 4 0 11-8 0 4 4 0 018 0z" />
            </svg>
          )}

          <div className="w-[60px] h-[60px] absolute">
            <CircularProgressbar
              value={percentage ? floor(percentage) : 0}
              strokeWidth={5}
              styles={styles}
            />
          </div>
        </div>
        <PlusCircleIcon
          onClick={(e) => setOpen(!open)}
          className="h-5 w-5 cursor-pointer absolute right-0 bottom-0 bg-white rounded-full text-[#1A5DBC]"
        />
      </div>
      {open ? (
        <DashboardModal
          uppy={uppy}
          open={open}
          closeAfterFinish={true}
          closeModalOnClickOutside={true}
          plugins={["ImageEditor"]}
          proudlyDisplayPoweredByUppy={false}
          onRequestClose={() => setOpen(false)}
          metaFields={[{ id: "name", name: "Name", placeholder: "File name" }]}
        />
      ) : null}
    </Fragment>
  );
};

export default LogoUploader;
