import { ChevronRightIcon } from "@heroicons/react/20/solid"
import classNames from "classnames";
import { useTranslation } from "react-i18next"

const SecondMainLayout = (props: any) =>{
    const {t} = useTranslation();
    const isMobile = window.innerWidth < 768;
    const mainPageLayout = [
        {
            title:t('identity.profile.accountAccess'),
            list:[
                {link:"SignInAndSecurity/EmailAddresses",title:t('identity.profile.emailEddresses'),value: props.user?.userName},
                {link: "SignInAndSecurity/PhoneNumbers",title:t('identity.profile.phoneNumbers'),value:null},
                {link:"SignInAndSecurity/ChangePassword",title:t('identity.profile.ChangePassword'),value:null},
                {link:"SignInAndSecurity/WhereYoureSignedIn",title:t('identity.profile.whereYoureSignedin'),value:null},
                {link:"SignInAndSecurity/TwoStepVerification",title:t('identity.profile.two-stepVerification'),value:null}
            ]
        }
    ]

    return(
        <div className="space-y-6  md:col-span-9 md:px-0 lg:col-span-8 2xl:col-span-9">
            {mainPageLayout.map((mainItem ,index) => (
            <div className={classNames("overflow-hidden bg-white ", {"rounded-[15px] shadow-[0_3px_5px_0_rgba(9,30,66,0.10)]":!isMobile})}>
                <ul role="list" className="divide-y divide-gray-200">
                    <li key={index}>
                        <div className="flex items-center px-4 py-4 sm:px-6">
                            <div className="min-w-0 flex-1 sm:flex sm:items-center sm:justify-between">
                                <div className="truncate">
                                    <div className="flex text-sm">
                                        <p className="truncate font-medium text-lg">{mainItem.title}</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </li>
                    {mainItem.list.map(item =>(
                        <li key={index}>
                            <div className="flex items-center px-4 py-4 sm:px-6">
                                <div className="min-w-0 flex-1 sm:flex sm:items-center sm:justify-between">
                                    <div onClick={()=>{props.handleClick(item.link)}} className="truncate cursor-pointer">
                                        <div className="flex text-sm">
                                            <p className="truncate font-medium text-md">{item.title}</p>
                                        </div>
                                    </div>
                                    <div className="hidden md:block">
                                        <div >
                                            <p className="text-sm text-gray-900">
                                                {item.value}
                                            </p>
                                            
                                        </div>
                                    </div>
                                </div>
                                <div className="ml-5 flex-shrink-0">
                                <ChevronRightIcon className="h-5 w-5 text-gray-400" aria-hidden="true" />
                                </div>
                            </div>
                        </li>
                    ))}
                </ul>
            </div>
            ))}
        </div>
    )

}

export default SecondMainLayout