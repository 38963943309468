import { Listbox } from "@headlessui/react";
import * as React from "react";
import { ExclamationCircleIcon } from "@heroicons/react/20/solid";
import classNames from "classnames";
import { Field, Form, Formik } from "formik";
import { DebounceInput } from "react-debounce-input";
import { Fragment, useState } from "react";
import Select from "react-select";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import {
  SelectInputCombobox,
  SelectTelCodeInput,
  TextInput,
  telephoneInput,
} from "../../components/FormFields";
import useGetCountries from "../../swr/Countries";
import {
  PhoneNumberTypes,
  RegisterNewPhoneNumberCommand,
  UserPhoneNumber,
  UserPhoneNumberDto,
  UserPreferencesClient,
} from "../../swagger";
import { axiosInstance } from "../../services/AxiosInstance";
import toast from "react-hot-toast";
import _ from "lodash";
import Loading from "../../components/loading";
type propType = {
  editPhoneNumber: Number;
  isEdit: Boolean;
  phoneNumber: UserPhoneNumberDto;
  setStep: (step:number) => void;
  hasPassword:boolean;
  setPhoneNumber:(phoneNumber:UserPhoneNumber) => void ;
  setShowTimer : any ;
  setRegisterNewPhoneNumberCommand : any
};

const NewPhoneNumberForm = (props: propType) => {
  const { t } = useTranslation();
  const { countries, isLoading, isError } = useGetCountries();
  const [query, setQuery] = useState("");
  const [value, onChange] = useState("");
  const [loading, setLoading] = useState<boolean>(false);
  const [showPassword, setShowPassword] = useState(false);

  const checkValidate = (values) => {
    let error = {} as any;

    if (!values.phoneCode || !values.phoneNumber || values.phoneNumber == "") {
      error.phoneNumber = t("identity.profile.enterAPhoneNumber")
    } else if (!/^\d+$/.test(values.phoneNumber)) {
      error.phoneNumber = t("identity.profile.thePhoneNumberIsNotValid")
    } else if (!values.type) {
      error.type = t("identity.profile.selectType")
    } else if(!values.password){
      error.password = t("identity.profile.typeCurrentPassword")
    }
    return error;
  };

  const onSubmit = async (values) => {
    setLoading(true);
    const client = new UserPreferencesClient(undefined, axiosInstance);
    try {
      const data = {
        userPhoneNumber: {
          phoneNumberType: values.type ? values.type.id : null,
          phoneCountryId: values.phoneCode ? values.phoneCode.icon : null,
          phoneCountry:values.phoneCode,
          phoneNumber: values.phoneNumber,
          id: props.editPhoneNumber ? props.editPhoneNumber : 0
        } as UserPhoneNumber,
        user: undefined,
        isResend: false,
        password: values.password,
      } as RegisterNewPhoneNumberCommand;

      var res = await client.addNewPhoneNumber(data);

      if (res && res.succeeded) {
        const updatedData = { ...data, isResend: true };
        setLoading(false);
        props.setPhoneNumber(data.userPhoneNumber);
        props.setRegisterNewPhoneNumberCommand(updatedData)
        props.setStep(3);
        props.setShowTimer(true)
      } else {
        setLoading(false);
        toast.error(_.first(res.errors) as any);
      }
    } catch (error) {
      setLoading(false);
      toast.error(t("identity.src.components.login.EnterEmail.somethingUnexpectedHappenedPleaseTryAgainLater"));
    }
    setLoading(false);
  };

  return (
    <div className="space-y-6 sm:px-6 lg:col-span-9 lg:px-0">
      <div className="bg-white pt-6 shadow sm:overflow-hidden sm:rounded-md">
        <div className="px-4 sm:px-6">
          <h2 className="text-lg font-medium leading-6 text-gray-900">
            {props.isEdit?t("identity.profile.editPhoneNumber"):t("identity.profile.NewPhoneNumber")}
          </h2>
        </div>
        {isLoading?
          <Loading />
        :
        <Formik
          onSubmit={onSubmit}
          initialValues={{
            phoneCode:
              props.phoneNumber && props.phoneNumber.phoneCountryId?
              countries? countries.filter(
                    (c) => c.icon == props.phoneNumber.phoneCountryId
                  )[0]
                : {icon:props.phoneNumber.phoneCountryId}:null ,
            phoneNumber: props.phoneNumber
              ? props.phoneNumber.phoneNumber
              : null,
            type:
              props.phoneNumber && props.phoneNumber.phoneNumberType
                ? Object.keys(PhoneNumberTypes)
                    .filter(
                      (item) => isNaN(Number(item))
                    )
                    .map((t, index) => ({
                      name: t,
                      id: index + 1,
                    })).filter(t => t.id ==  props.phoneNumber.phoneNumberType)[0]
                : null,
          }}
          validate={checkValidate}
        >
          {({
            values,
            errors,
            touched,
            handleChange,
            handleBlur,
            handleSubmit,
            isSubmitting,
            setFieldValue,
            /* and other goodies */
          }) => (
            <Form onSubmit={handleSubmit}>
              <div className="mt-6 flex flex-col e relative">
                <div className="-my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
                  <div className="inline-block min-w-full align-middle sm:px-6 lg:px-8">
                    <div className=" border-t border-gray-200">
                      <div className="p-6 !pb-11 grid grid-cols-1 gap-y-6">
                        <div className="w-[60%] h-fit overflow-visibl flex flex-col space-y-4">
                          <div className="">
                            <label className="flex mb-2 text-sm font-medium text-gray-700">
                              {t("identity.profile.phoneNumber")}
                            </label>
                            <div className="flex border rounded-lg">
                              <Field
                                name="phoneCode"
                                component={SelectTelCodeInput}
                                options={countries ? countries : []}
                                placeholder="code"
                                disabled={!props.hasPassword}
                                onChange={(option) =>
                                  setFieldValue("phoneCode", option)
                                }
                                cssClasses = "ring-0 focus:border-0 focus:ring-0 focus:outline-0 "
                              />
                              <Field
                                value={values.phoneNumber}
                                disabled={!props.hasPassword}
                                onChange={handleChange}
                                name="phoneNumber"
                                type="tel"
                                component={telephoneInput}
                                placeholder="Telephone"
                              />
                            </div>
                            {errors.phoneNumber ? (
                              // && touched.userLoginEmail
                              <p className="text-red-500 text-xs italic">
                                {errors.phoneNumber.toString()}
                              </p>
                            ) : null}
                          </div>
                          <div className="flex text-sm ml-1 mt-1 text-gray-400">
                            {!props.hasPassword ? (
                              <div>
                                {t("identity.emailAddresses.please")}{" "}
                                <Link to="/account/ForgetPassword">
                                  <a className="text-blue-700 underline cursor-pointer">
                                    {t("identity.emailAddresses.setAPassword")}
                                  </a>
                                </Link>{" "}
                                {t('identity.emailAddresses.first')}
                              </div>
                            ) : (
                              t(
                                "identity.emailAddresses.AConfirmationWillBeSentToThisAccountClickOnTheConfirmationLinkToVerifyAndAddThisEmail"
                              )
                            )}
                          </div>

                          <div className="w-[100%] h-fit flex flex-col gap-y-1">
                            <p className="flex text-sm font-medium text-gray-700">{t("identity.profile.type")}</p>
                            <Field
                              name="type"
                              // title={t("identity.profile.type")}
                              disabled={!props.hasPassword}
                              component={SelectInputCombobox}
                              options={Object.keys(PhoneNumberTypes)
                                .filter((item) => isNaN(Number(item)))
                                .map((t, index) => ({
                                  name: t,
                                  id: index + 1,
                                }))}
                              query={query}
                              value={value}
                              onChange={(e: any) => {
                                onChange(e);
                                setFieldValue("type", e);
                                
                              }}
                              setQuery={setQuery}
                            />
                          </div>
                          <div className="w-[100%] h-fit flex flex-col gap-y-1">
                          <p className="flex text-sm font-medium text-gray-700">{t("identity.profile.typeYourCurrentPassword")}</p>
                            <Field
                              disabled={!props.hasPassword}
                              name="password"
                              // title={t("identity.profile.typeYourCurrentPassword")}
                              component={TextInput}
                              showPassword = {showPassword}
                              setShowPassword = {setShowPassword}
                              isPassword
                            />
                          </div>

                          <div className="flex flex-row space-x-2 justify-start pt-8 ">
                            <div
                              onClick={() => props.setStep(1)}
                              className="rounded-md flex flex-row justify-center items-center cursor-pointer w-20 bg-white py-2 px-3 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50"
                            >
                              {t("identity.basicProfileInfo.Back")}
                            </div>
                            <button
                              type="submit"
                              disabled={!props.hasPassword || !_.isEmpty(errors)}
                              className={`${
                                !props.hasPassword || !_.isEmpty(errors)
                                  ? "cursor-not-allowed "
                                  : "cursor-pointer "
                              }   w-fit h-fit py-2 px-10 inline-flex justify-center rounded-md bg-[#1A5DBC]  text-sm font-semibold text-white shadow-sm focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-500`}
                            >
                              {loading ? (
                                <>
                                  <svg
                                    aria-hidden="true"
                                    className="w-5 h-5 text-gray-200 animate-spin dark:text-gray-600 fill-blue-600"
                                    viewBox="0 0 100 101"
                                    fill="none"
                                    xmlns="http://www.w3.org/2000/svg"
                                  >
                                    <path
                                      d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
                                      fill="currentColor"
                                    />
                                    <path
                                      d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
                                      fill="currentFill"
                                    />
                                  </svg>
                                </>
                              ) : (
                                <>{t("identity.basicProfileInfo.SendCode")}</>
                              )}
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </Form>
          )}
        </Formik>
        }
      </div>
    </div>
  );
};
export default NewPhoneNumberForm;
