import { ChevronRightIcon } from "@heroicons/react/20/solid"

const MainLayout = (props) =>{
    const mainPageLayout = [
        {
            title:'Profile information',
            list:[{name:'Basic Profile info' , link:'AccountPreferences/ProfileInformation'}]
        },
        // {
        //     title:'General preferences',
        //     list:['Language']
        // },
        // {
        //     title:'Account management',
        //     list:['Merge accounts','Hibernate account','Close Account']
        // }
    ]
    return(
        <div className="space-y-6  md:col-span-9 md:px-0 lg:col-span-8 2xl:col-span-9">
            {mainPageLayout.map((mainItem ,index) => (
            <div className="overflow-hidden bg-white shadow-[0_3px_5px_0_rgba(9,30,66,0.10)] rounded-[15px]">
                <ul role="list" className="divide-y divide-gray-200">
                    <li key={index}>
                        <div className="flex items-center px-4 py-4 sm:px-6 ">
                            <div className="min-w-0 flex-1 sm:flex sm:items-center sm:justify-between">
                                <div className="truncate">
                                    <div className="flex text-sm">
                                        <p className="truncate font-medium text-lg">{mainItem.title}</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </li>
                    {mainItem.list.map((item , index) =>(
                        <li key={index}>
                            <div onClick={()=>{props.handleClick(item.link)}} className="flex items-center px-4 py-4 sm:px-6 cursor-pointer">
                                <div className="min-w-0 flex-1 sm:flex sm:items-center sm:justify-between">
                                    <div className="truncate">
                                        <div className="flex text-sm">
                                            <p className="truncate font-medium text-md">{item.name}</p>
                                        </div>
                                    </div>
                                </div>
                                <div className="ml-5 flex-shrink-0">
                                <ChevronRightIcon className="h-5 w-5 text-gray-400" aria-hidden="true" />
                                </div>
                            </div>
                        </li>
                    ))}
                </ul>
            </div>
            ))}
        </div>
    )

}

export default MainLayout